function path(...args: string[]) {
  return args.join("");
}

export const calc = "/calc";
export const app = "/app";
export const signIn = "/sign-in";
export const signUp = "/sign-up";
export const test = path(app, "/test");
export const newPatient = path(app, "/new-patient");
export const myPatients = path(app, "/my-patients");
export const patient = path(app, "/patient/{id}");
export const settings = path(app, "/settings");
export const myAssistants = path(settings, "/my-assistants");
export const linkPatient = path(app, "/link-patient");
export const profile = path(app, "/profile");
const ROUTES = {
  app,
  test,
  settings,
  newPatient,
  myPatients,
  myAssistants,
  linkPatient,
  patient,
  signIn,
  signUp,
  profile,
  calc,
};

export default ROUTES;
