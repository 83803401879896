import dayjs from "dayjs";
import { type Appointment } from "@prisma/client";

export const getAge = (bdate?: string | Date) => {
  if (!bdate) return null;
  return dayjs.utc().diff(dayjs.utc(bdate), "years");
};

export const getNextAppointment = (orderedAppointments?: Appointment[]) => {
  const TODAY = dayjs.utc();
  if (!orderedAppointments) return null;
  return orderedAppointments.reduce<Appointment | null>((curr, appointment) => {
    if (!curr) return appointment;
    if (
      dayjs.utc(curr.date).isAfter(TODAY) &&
      dayjs.utc(appointment.date).isBefore(curr.date)
    )
      return appointment;
    return curr;
  }, null);
};
