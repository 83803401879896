import {
  Gender,
  RacialOrigin,
  BloodGroupType,
  BloodRHFactorType,
  EducationLevel,
  EducationStatus,
  CycleAlterationType,
  MenstrualVolumeType,
  DeliveryMethod,
  ConceptionType,
} from "@prisma/client";

export const MENSTRUAL_VOLUME_TYPES = [
  {
    value: MenstrualVolumeType.abundant,
    label: "menstrualVolumeTypeOptions.abundant",
  },
  {
    value: MenstrualVolumeType.normal,
    label: "menstrualVolumeTypeOptions.normal",
  },
  { value: MenstrualVolumeType.null, label: "menstrualVolumeTypeOptions.null" },
  {
    value: MenstrualVolumeType.scant,
    label: "menstrualVolumeTypeOptions.scant",
  },
];
export const CYCLE_ALTERATION_TYPES = [
  {
    value: CycleAlterationType.amenorrea,
    label: "cycleAlterationTypeOptions.amenorrea",
  },
  {
    value: CycleAlterationType.hipermenorrea,
    label: "cycleAlterationTypeOptions.hipermenorrea",
  },
  {
    value: CycleAlterationType.hipomenorrea,
    label: "cycleAlterationTypeOptions.hipomenorrea",
  },
  {
    value: CycleAlterationType.menometrorragia,
    label: "cycleAlterationTypeOptions.menometrorragia",
  },
  { value: CycleAlterationType.null, label: "cycleAlterationTypeOptions.null" },
  {
    value: CycleAlterationType.oligomenorrea,
    label: "cycleAlterationTypeOptions.oligomenorrea",
  },
  {
    value: CycleAlterationType.polimenorrea,
    label: "cycleAlterationTypeOptions.polimenorrea",
  },
];
export const GENDER_OPTIONS = [
  { value: Gender.female, label: "female" },
  { value: Gender.male, label: "male" },
];

export const RACIAL_ORIGIN_OPTIONS = [
  { value: RacialOrigin.african, label: "racialOriginOptions.african" },
  { value: RacialOrigin.asian, label: "racialOriginOptions.asian" },
  { value: RacialOrigin.caucasian, label: "racialOriginOptions.caucasian" },
  {
    value: RacialOrigin.hispanicOrlatino,
    label: "racialOriginOptions.hispanicOrlatino",
  },
  { value: RacialOrigin.indigenous, label: "racialOriginOptions.indigenous" },
  { value: RacialOrigin.mixed, label: "racialOriginOptions.mixed" },
  { value: RacialOrigin.other, label: "racialOriginOptions.other" },
];
export const BLOOD_GROUP_TYPE = [
  { value: BloodGroupType.A, label: "A" },
  { value: BloodGroupType.AB, label: "AB" },
  { value: BloodGroupType.B, label: "B" },
  { value: BloodGroupType.O, label: "O" },
];

export const BLOOD_FACTOR_TYPE = [
  { value: BloodRHFactorType.positive, label: "+" },
  { value: BloodRHFactorType.negative, label: "-" },
];
export const EDUCATION_LEVEL = [
  { value: EducationLevel.primary, label: "educationLevelOptions.primary" },
  { value: EducationLevel.secondary, label: "educationLevelOptions.secondary" },
  { value: EducationLevel.tertiary, label: "educationLevelOptions.tertiary" },
  {
    value: EducationLevel.university,
    label: "educationLevelOptions.university",
  },
  {
    value: EducationLevel.postgraduate,
    label: "educationLevelOptions.postgraduate",
  },
];
export const EDUCATION_STATUS = [
  { value: EducationStatus.complete, label: "complete" },
  { value: EducationStatus.incomplete, label: "incomplete" },
];

export const MEDICAL_SPECIALTIES = [
  { value: "obstetrics", label: "medicalSpecialties.obstetrics" },
  { value: "gynecology", label: "medicalSpecialties.gynecology" },
];
export const DELIVERY_METHODS = [
  { value: DeliveryMethod.cesarean, label: "cesarean" },
  { value: DeliveryMethod.partum, label: "partum" },
  {
    value: DeliveryMethod.instrumentedMiscarriage,
    label: "instrumentedMiscarriage",
  },
  {
    value: DeliveryMethod.spontaneusMiscarriage,
    label: "spontaneusMiscarriage",
  },
];

export const CONCEPTION_TYPE_OPTIONS = [
  { value: ConceptionType.spontaneous, label: "spontaneous" },
  { value: ConceptionType.inVitroFertilization, label: "inVitroFertilization" },
  { value: ConceptionType.ovulationDrugs, label: "ovulationDrugs" },
];

export const LOCAL_STORAGE_LANG_KEY = "ALMA_LANG";

export const TIMEZONE = "Etc/UTC";
