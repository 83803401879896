import type { RouterOutputs } from "@/utils/api";
import { fmf } from "@/utils";
import { type Study, DeliveryMethod } from "@prisma/client";
import { un } from "@/utils";
import { type Unit } from "mathjs";
import { romanize } from "./number";
import dayjs from "dayjs";

interface GaStudy extends Study {
  ga: number;
  rank: number;
}

export const getPregnancyGA = (
  patient: RouterOutputs["patients"]["getById"]
) => {
  let ga = null;
  let gaAt = null;
  // on-going
  const currentPregnancy = getCurrentPregnancy(patient);
  // If no on-going pregnancy return null
  if (!currentPregnancy) return null;

  //  // GA desde fecha última menstruación
  const lmp = fmf.gaFromLmp(currentPregnancy.lastMenstrualPeriodDate);

  ga = lmp;
  gaAt = dayjs.utc();

  //  // Si tiene estudio  entre 12-14 semanas saco lcc (CRL)
  const studyType = "pregnancy";
  const studySubType = "obstetricUltrasound";

  const GAsRanked = patient?.studies
    .reduce((acc: GaStudy[], study) => {
      if (study.type === studyType && study.subType === studySubType) {
        const crl = un(study.data.crl);
        if (crl) {
          // crl 30-84
          const gaFromCrl = fmf.gaFromCrl(crl.to("mm").toNumber(), 30, 84);
          if (gaFromCrl) {
            return [...acc, { ...study, ga: gaFromCrl, rank: 1 }];
          } else {
            // crl 0-30
            const gaFromCrlLessThan30 = fmf.gaFromCrl(
              crl.to("mm").toNumber(),
              0,
              29
            );
            if (gaFromCrlLessThan30) {
              return [...acc, { ...study, ga: gaFromCrlLessThan30, rank: 2 }];
            }
          }
        }
        const hc = un(study.data.hc);
        if (hc) {
          const gaFromHc = fmf.gaFromHc(hc.to("mm").toNumber());
          if (gaFromHc) {
            return [...acc, { ...study, ga: gaFromHc, rank: 3 }];
          }
        }
      }
      return acc;
    }, [])
    .sort((a, b) => a.rank - b.rank);

  if (GAsRanked && GAsRanked?.length >= 1) {
    let study = GAsRanked[0];
    const studyRank = study?.rank;
    if (GAsRanked.filter((s) => s.rank === studyRank).length > 1) {
      study = GAsRanked.sort((a, b) => b.date.getTime() - a.date.getTime())[0];
    }
    if (study) {
      ga = study.ga;
      gaAt = dayjs.utc(study.date);
    }
  }

  const parsedGa = fmf.daysToOWObject(ga);

  return {
    ...parsedGa,
    at: gaAt,
  };
};

export const getPregnancyGAToday = (
  patient: RouterOutputs["patients"]["getById"]
) => {
  const today = dayjs.utc().toDate();
  const ga = getPregnancyGAAt(patient, today);
  if (!ga) return null;
  return `${ga.weeks}+${ga.days} Semanas`;
};

export const getPregnancyGAAt = (
  patient: RouterOutputs["patients"]["getById"],
  date?: string | Date
) => {
  const lastGa = getPregnancyGA(patient);
  if (!lastGa) return null;

  const { weeks, days, at } = lastGa;
  if (!weeks || !days || at) return null;
  // Add the diff between GA date and now.
  const refDate = dayjs.utc(date);
  const diff = dayjs.duration(refDate.diff(at)).as("days");
  const totalDays = dayjs
    .duration(weeks, "weeks")
    .add(days, "days")
    .add(diff, "days")
    .as("days");

  const parsedGa = fmf.daysToOWObject(totalDays);

  return parsedGa;
};

export const getCurrentPregnancy = (
  patient: RouterOutputs["patients"]["getById"]
) => {
  // on-going
  return patient?.pregnancies.find((p) => !p.endDate && !p.newbornBirthDate);
};

export const parseParity = (parity?: PrismaJson.Parity) => {
  if (!parity) return "";
  const map = {
    gestas: "G",
    para: "P",
    cesareans: "C",
    miscarriage: "Ab",
  };

  return Object.entries(parity)
    .map(([key, val]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const kk = map[key];
      if (kk && !Number.isNaN(val) && val > 0) {
        return `${romanize(val)}${kk}`;
      }
      return null;
    })
    .filter(Boolean)
    .join("-");
};

export const getCurrentPregnancyEFW = (
  patient: RouterOutputs["patients"]["getById"]
) => {
  // on-going
  const currentPregnancy = getCurrentPregnancy(patient);
  // If no on-going pregnancy return null
  if (!currentPregnancy) return null;

  const studyType = "pregnancy";
  const studySubType = "obstetricUltrasound";

  const filteredStudies = patient?.studies?.filter(
    ({ type, subType }) => type === studyType && subType === studySubType
  );

  const sortedStudies = filteredStudies?.sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );

  if (!sortedStudies || sortedStudies.length === 0) return null;

  let efw: Unit | null | undefined;
  let studyDate: Date | undefined = undefined;
  // 1. Get Fetal weight from last study
  sortedStudies.some((study) => {
    const { data } = study;

    const hc = un(data.hc),
      ac = un(data.ac),
      fl = un(data.fl);
    if (hc && ac && fl) {
      const calculatedEFW = fmf.calculateEFWFromBiometry({
        hc: hc.to("mm").toNumber(),
        ac: ac.to("mm").toNumber(),
        fl: fl.to("mm").toNumber(),
      });
      if (calculatedEFW) {
        efw = un({ value: calculatedEFW, unit: "g" })?.to("g");
        studyDate = study.date;
        return true;
      }
    } else if (data?.efw?.value) {
      efw = un(data.efw);
      studyDate = study.date;
      return true;
    }
    return false;
  });

  if (!efw || studyDate === undefined) return null;

  // 2. Get Gestational Age at efw calculation date
  const gaAtStudyDate = getPregnancyGAAt(patient, studyDate);
  if (!gaAtStudyDate?.weeks || !gaAtStudyDate?.days) return null;

  const gaAsDays = dayjs
    .duration(gaAtStudyDate.weeks, "weeks")
    .add(gaAtStudyDate.days, "days")
    .as("days");

  // 3. percentil del peso fetal resuelto del estudio  con la edad gestacional al momento del estudio
  const centileStudy = fmf.centileFromFW(efw.toNumber(), gaAsDays);

  if (!centileStudy) return null;

  const today = dayjs.utc();
  const gaToday = getPregnancyGAAt(patient, today.toDate());
  if (!gaToday?.weeks || !gaToday?.days) return null;

  const gaTodayAsDays = dayjs
    .duration(gaToday.weeks, "weeks")
    .add(gaToday.days, "days")
    .as("days");

  // 4. obtener el peso desde edada gestacional y percentil
  const fw = Number(fmf.calcEFW(gaTodayAsDays, centileStudy)?.toFixed(2));
  if (!fw) return null;

  return un({ value: fw, unit: "g" })?.to("g").toString();
};

export function isMedicalHistoryComplete(
  patient?: RouterOutputs["patients"]["getById"]
) {
  // TODO: COMPLETE
  if (!patient) return false;
  if (!patient.medicalHistory) return false;
  if (!patient.medicalHistory.height?.value) return false;
  return true;
}

export function buildParity(patient?: RouterOutputs["patients"]["getById"]) {
  const pregnancies = patient?.pregnancies;

  return pregnancies?.reduce<PrismaJson.Parity>(
    (acc, pregnancy) => {
      const deliveryMethod = pregnancy?.deliveryMethod;
      acc.gestas += 1;
      if (deliveryMethod === DeliveryMethod.cesarean) acc.cesareans += 1;
      if (deliveryMethod === DeliveryMethod.partum) acc.para += 1;
      if (
        deliveryMethod === DeliveryMethod.instrumentedMiscarriage ||
        deliveryMethod === DeliveryMethod.spontaneusMiscarriage
      )
        acc.miscarriage += 1;
      return acc;
    },
    {
      gestas: 0,
      para: 0,
      cesareans: 0,
      miscarriage: 0,
    }
  );
}
